<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.name)"
          :variant="`light-dark`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">{{ userData.name }} {{ userData.lastname }}</h4>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Name')" label-for="name">
            <b-form-input id="name" v-model="userData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Last Name')" label-for="last-name">
            <b-form-input id="last-name" v-model="userData.lastname" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email" v-model="userData.email" type="email" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Phone')" label-for="phone">
            <b-form-input
              id="phone"
              class="form-control"
              v-model="formatPhone"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group label="Date Birth" label-for="date_birth">
            <flat-pickr
              id="creation"
              v-model="userData.date_birth"
              :config="config"
              class="form-control"
              placeholder="MM-DD-YYYY"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Gender')" label-for="gender">
            <v-select
              v-model="userData.gender"
              :options="genderOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group label="Address" label-for="address">
            <b-form-input id="address" v-model="userData.address" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.City')" label-for="city">
            <v-select
              v-model="userData.cities_id"
              :options="optionsCities"
              @search="searchCities"
              :reduce="(val) => val.id"
              label="name"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group label="Zip Code" label-for="zipcode">
            <b-form-input id="zip_code" v-model="userData.zip_codes_id" />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Status')" label-for="user-status">
            <v-select
              v-model="userData.status"
              :options="statusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="saveChanges"
      :disabled="loading"
    >
      <span v-if="!loading">Save Changes</span>
      <span v-else>
        <SpinnerLoading />
      </span>
    </b-button>

    <!-- Action Buttons -->

    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import flatPickr from "vue-flatpickr-component";

import axios from "axios";
import axiosP from "@/services/patient";
import axiosC from "@/services/admin/cities";

import SpinnerLoading from "@/components/SpinnerLoading";
import ToastNotification from "@/components/ToastNotification";
import { fromConfig } from "@/mixins/configFormatCal";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormFile,
    vSelect,
    flatPickr,
    SpinnerLoading,
    ToastNotification,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    formatPhone: {
      get() {
        let { phone } = this.userData;
        if (phone) {
          phone = phone.toString().replace(/\D/g, "");
          const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
          if (match) {
            phone = `(${match[1]}${match[2] ? "" : ""}) ${match[2]}${
              match[3] ? "-" : ""
            }${match[3]}`;
          }
          return phone;
        } else {
          return "";
        }
      },
      set(val) {
        this.userData.phone = val;
      },
    },
  },
  mounted() {
    this.selectedFacilities = this.userData.facilities;
  },
  data() {
    return {
      statusOptions: [
        { label: "Active", value: true },
        { label: "Inactive", value: false },
      ],
      genderOptions: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
      ],
      config: fromConfig({ dateFormat: "m-d-Y", }),
      fileContents: null,
      file: null,
      tags: "browser-upload",
      photoSelected: null,
      loading: false,
      setTimeoutBuscador: "",
      optionsCities: [],
    };
  },
  methods: {
    avatarText(name) {
      return avatarText(name);
    },
    saveChanges() {
      const phone = isNaN(this.userData.phone)
        ? this.userData.phone.replace(/[() -]/g, "")
        : this.userData.phone;
      axiosP
        .patientUpdate(this.userData.id, { ...this.userData, phone })
        .then(({ status }) => {
          if (status === "ERROR") {
            this.$refs.toast.danger("Error updating user");
          } else {
            this.$refs.toast.success("User updated successfully");
          }
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          this.$refs.toast.danger("Error updating user");
        });
    },

    searchCities(city, loading) {
      if (city.length) {
        loading(true);
        clearTimeout(this.setTimeoutBuscador);
        const datos = {
          name: city,
        };
        this.setTimeoutBuscador = setTimeout(() => {
          axiosC.citiesFilterFilters(datos).then(({ data }) => {
            loading(false);
            this.optionsCities = data;
          });
        }, 350);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.media-body {
  align-self: center;
}
</style>
