import { userAxios } from '../index'

const appointmentRefundList = async (perPage) => {
    try {
        return await userAxios.get(`appointment/refund/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentRefundView = async (appoinment) => {
    try {
        return await userAxios.get(`appointment/refund/invoice/${appoinment}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const refundPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`appointment/refund/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const refundFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`appointment/refund/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const refundFilterPagination = async (perPage, page, data) => {
    try {
        return await userAxios.post(`appointment/refund/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    appointmentRefundView,
    appointmentRefundList,
    refundPagination,
    refundFilter,
    refundFilterPagination
}