<template>
  <b-modal
    v-model="conditionCancel"
    @hide="onHide"
    hide-footer
    hide-header
    centered
    size="md"
  >
    <validation-observer #default="{ handleSubmit }">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="handleSubmit(onSubmitCancel)"
        v-if="dataAppointment"
      >
        <validation-provider
          #default="{ errors }"
          name="insurance type"
          rules="required"
        >
          <b-form-group label="Insurance Type" label-for="insurance-type">
            <v-select
              id="insurance-type"
              v-model="form.insurance_type_id"
              :options="insuranceOptions"
              @search="searchTypeInsurance"
              :reduce="(value) => value.id"
              label="name"
            >
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <div v-if="methodCB != 'copay'">
          <validation-provider
            #default="{ errors }"
            name="co_insurance"
            rules="required"
          >
            <b-form-group label="Co Insurance" label-for="co_insurance">
              <b-form-input
                id="co_insurance"
                name="co_insurance"
                v-model="form.co_insurance"
                :state="errors.length > 0 ? false : null"
                type="number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="deductible"
            rules="required"
          >
            <b-form-group label="deductible" label-for="deductible">
              <b-form-input
                id="deductible"
                name="deductible"
                v-model="form.deductible"
                :state="errors.length > 0 ? false : null"
                type="number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </div>
        <div v-else>
          <validation-provider
            #default="{ errors }"
            name="convenience_fee"
            rules="required"
          >
            <b-form-group label="convenience fee" label-for="convenience_fee">
              <b-form-input
                id="convenience_fee"
                name="convenience_fee"
                v-model="form.convenience_fee"
                :state="errors.length > 0 ? false : null"
                type="number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Is Split Pay"
            rules="required"
          >
            <b-form-group
              label="Is Split Pay"
              label-for="Is Split Pay"
              :state="errors.length > 0 ? false : null"
              class="my-1"
            >
              <v-select
                id="Is Split Pay"
                v-model="form.is_split_pay"
                :options="conditionalOptions"
                :reduce="(value) => value.id"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </div>
        <div v-if="methodCB != 'uInsurance' && form.is_split_pay">
          <b-row>
            <b-col>
              <validation-provider #default="{ errors }" name="cash">
                <b-form-group label="Cash" label-for="cash">
                  <b-form-input
                    id="cash"
                    name="cash"
                    v-model="form.cash.value"
                    :state="errors.length > 0 ? false : null"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="Transaction Id"
                rules="required"
              >
                <b-form-group
                  label="Cash Transaction Id"
                  label-for="Transaction Id"
                >
                  <b-form-input
                    id="transaction_id_cash"
                    :disabled="!form.cash.value"
                    name="Transaction Id"
                    v-model="form.cash.transaction_id"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <validation-provider #default="{ errors }" name="Credit card">
                <b-form-group label="Credit card" label-for="Credit card">
                  <b-form-input
                    id="Credit_card"
                    name="Credit card"
                    v-model="form.cc.value"
                    :state="errors.length > 0 ? false : null"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="CC Transaction ID"
                rules="required"
              >
                <b-form-group
                  label="CC Transaction ID"
                  label-for="CC Transaction ID"
                >
                  <b-form-input
                    id="cc_transaction_id"
                    name="CC Transaction ID"
                    :disabled="!form.cc.value"
                    v-model="form.cc.transaction_id"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
        <div v-else-if="form.is_split_pay == false">
          <validation-provider
            #default="{ errors }"
            name="payment method"
            rules="required"
          >
            <b-form-group
              label="payment method"
              label-for="payment method"
              :state="errors.length > 0 ? false : null"
              class="my-1"
            >
              <v-select
                id="payment method"
                v-model="paymentMethod"
                :options="paymentMethodOptions"
                :reduce="(value) => value.id"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <b-row v-if="paymentMethod">
            <b-col>
              <validation-provider #default="{ errors }" name="cash">
                <b-form-group label="Cash" label-for="cash">
                  <b-form-input
                    id="cash"
                    name="cash"
                    v-model="form.cash.value"
                    :state="errors.length > 0 ? false : null"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="Transaction Id"
                rules="required"
              >
                <b-form-group
                  label="Cash Transaction Id"
                  label-for="Transaction Id"
                >
                  <b-form-input
                    id="transaction_id_cash"
                    name="Transaction Id"
                    :disabled="!form.cash.value"
                    v-model="form.cash.transaction_id"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <validation-provider #default="{ errors }" name="Credit card">
                <b-form-group label="Credit card" label-for="Credit card">
                  <b-form-input
                    id="Credit_card"
                    name="Credit card"
                    v-model="form.cc.value"
                    :state="errors.length > 0 ? false : null"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="CC Transaction ID"
                rules="required"
              >
                <b-form-group
                  label="CC Transaction ID"
                  label-for="CC Transaction ID"
                >
                  <b-form-input
                    id="cc_transaction_id"
                    :disabled="!form.cc.value"
                    name="CC Transaction ID"
                    v-model="form.cc.transaction_id"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
        <div class="d-flex justify-content-end mt-2">
          <b-button
            type="button"
            class="mr-2"
            variant="outline-danger"
            @click="onHide"
          >
            {{ $t("Cancel") }}
          </b-button>
          <b-button variant="warning" type="submit" :disabled="loading">
            <span v-if="!loading">Send</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTextarea,
  BCol,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

import SpinnerLoading from "@/components/SpinnerLoading";

import axiosOA from "@/services/appointment/actions";
import axiosA from "@/services/appointment";
import axiosRcm from "@/services/rcm";

import NotificationMixin from "@/mixins/NotificationMixin";

export default {
  components: {
    BModal,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,

    flatPickr,
    vSelect,

    ValidationProvider,
    ValidationObserver,

    SpinnerLoading,
  },
  props: {
    appointmentById: {
      type: Number,
      required: true,
    },
    methodCB: {
      type: String,
      required: true,
    },
  },
  mixins: [NotificationMixin],
  data() {
    return {
      conditionCancel: true,
      conditionSplit: true,
      loading: false,
      form: {},
      insuranceOptions: [],
      dataAppointment: null,
      conditionalOptions: [
        {
          name: "Yes",
          id: true,
        },
        {
          name: "No",
          id: false,
        },
      ],
      paymentMethod: null,
      paymentMethodOptions: [
        {
          name: "Cash",
          id: true,
        },
        {
          name: "CC",
          id: false,
        },
      ],
      typeOptions: [
        {
          name: "Appointment",
          id: "appointment",
        },
        {
          name: "Membership",
          id: "membership",
        },
      ],
    };
  },
  mounted() {
    this.getData();
    this.searchTypeInsurance('\n')
  },
  methods: {
    searchTypeInsurance(name = "") {
      let clearTimeoutBuscador;
      if (name.length || name == '\n') {
        const datos = {
          name,
        };
        clearTimeout(clearTimeoutBuscador);
        clearTimeoutBuscador = setTimeout(() => {
          axiosRcm
            .searchTypeInsurance(25, datos)
            .then(({ registro: { data } }) => {
              this.insuranceOptions = data;
            });
        }, 400);
      }
    },
    onHide() {
      this.$emit("closeModalPay", false);
    },
    getData() {
      axiosA
        .appointmentDetail(this.appointmentById)
        .then(({ registro: { appointment } }) => {
          this.dataAppointment = appointment;
          if (this.methodCB == "copay")
            this.form = {
              convenience_fee: 0,
              is_split_pay: true,
              cash: {
                value: 0,
                transaction_id: null,
              },
              cc: {
                value: 0,
                transaction_id: null,
              },
            };
          if (this.methodCB == "tInsurance")
            this.form = {
              co_insurance: 0,
              deductible: 0,
              cash: {
                value: 0,
                transaction_id: null,
              },
              cc: {
                value: 0,
                transaction_id: null,
              },
            };
          if (this.methodCB == "uInsurance")
            this.form = {
              co_insurance: 0,
              deductible: 0,
            };
        });
    },
    async onSubmitCancel() {
      this.loading = true;
      const data = {
        co_insurance: this.form.co_insurance,
        deductible: this.form.deductible,
        insurance_type_id: this.form.insurance_type_id,
        payment: {
          ...this.form,
        },
      };
      const method = {
        copay: "appointmentAddCopay",
        tInsurance: "appointmentChangeInsurance",
        uInsurance: "appointmentUpdateInsuranceInfo",
      };
      await axiosOA[method[this.methodCB]](this.appointmentById, data)
        .then((res) => {
          const { type, message } =
            NotificationMixin.methods.notificationCheck(res);
          this.loading = false;
          this.$emit("paymentChecked", true);
          this.$emit("NotificationCheck", { type, message });
        })
        .catch((e) => {
          this.loading = false;
        });
      this.$emit("closeModalPay", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
