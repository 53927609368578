<template>
  <b-card class="p-0 m-0">
    <div>
      <b-table :items="appointmentData" :fields="fields" striped bordered responsive
        class="position-relative text-center patient-appoinment" hover>
        <template #cell(show_details)="row">
          <!-- activar el checkboz actual y desactivar los demas -->
          <b-form-checkbox v-model="row.detailsShowing" class="text-center mx-auto"
            @change="changeDetails(row, row.item.id)">
          </b-form-checkbox>
        </template>
        <template #row-details="row">
          <b-tabs>
            <b-tab active title="Visit Summary">
              <PatientsAppointmentView :appointmentDetail="row" />
            </b-tab>
            <b-tab title="Invoice" style="width: 75vw !important">
              <b-row>
                <b-col sm="12" md="12" lg="12">
                  <RecipePatient :isReview="true" :AppointmentId="row.item.id" @copayIsApplied="changeCopay" />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab v-if="copay != null" title="Co-pay" style="width: 75vw !important">
              <b-col sm="12" md="12" lg="12">
                <RecipeInsurancePayment :isPatientView="true" :dataPatient="{
                  payment: copay,
                  ...userData,
                }" />
              </b-col>
            </b-tab>
          </b-tabs>
        </template>

        <template #cell(type_visits)="data">
          <div class="text-nowrap">
            <b-badge :style="{ 'background-color': data.item.type_visits.color }"
              v-b-tooltip.hover.top="data.item.type_visits.name" class="badge-glow">
              <span class="align-text-top text-capitalize">{{
                data.item.type_visits.prefix
              }}</span>
            </b-badge>
          </div>
        </template>

        <template #cell(visit_status)="data">
          <div class="text-nowrap">
            <b-badge :style="{ 'background-color': getStatus(data.item.office_visit, 'color') }"
              v-b-tooltip.hover.top="getStatus(data.item.office_visit, 'name')" class="badge-glow">
              <span class="align-text-top text-capitalize">{{
                getStatus(data.item.office_visit, 'prefix')
              }}</span>
            </b-badge>
          </div>
        </template>

        <template #cell(type_consultations)="data">
          <span>{{
            data.item.type_consultations
              ? data.item.type_consultations.name
              : "Not specified"
          }}</span>
        </template>

        <template #cell(facilities)="data">
          <b-link :to="{
            name: 'admin-facilities-view',
            params: { id: data.item.facilities.id },
          }">
            <span>{{ data.item.facilities.name }}</span>
          </b-link>
        </template>

        <template #cell(frontdesks)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.frontdesks.avatar" />
            </template>
            <b-link :to="{
              name: 'web-settings-users',
              params: { id: data.item.frontdesks.id },
            }">
              <span class="font-weight-bold d-block text-nowrap d-flex flex-column align-items-start">
                <span>
                  {{ data.item.frontdesks.name }}
                </span>
                <span>
                  {{ data.item.frontdesks.lastname }}
                </span>
              </span>
            </b-link>
          </b-media>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button v-if="
              data.item.office_visit.visit_statuses_id != 6 &&
              $can('appointment_refund', 'module_patient.patient')
            " variant="flat-warning" class="btn-icon" v-b-tooltip.hover.top="'Refund'"
              @click="refundAppointment(data.item.id)">
              <feather-icon icon="RotateCcwIcon" />
            </b-button>
            <b-button v-if="
              isOpenThisVisit(data.item.office_visit.visit_statuses_id) &&
              !data.item.is_insurance_appointment &&
              $can('appointment_transform_to_insurance', 'module_patient.patient')
            " variant="flat-primary" class="btn-icon" v-b-tooltip.hover.top="'Transform to insurance'"
              @click="ChangeBasics(data.item.id, 'tInsurance')">
              <feather-icon icon="LockIcon" />
            </b-button>
            <b-button v-if="
              isOpenThisVisit(data.item.office_visit.visit_statuses_id) &&
              data.item.is_insurance_appointment &&
              $can('appointment_update_insurance', 'module_patient.patient')
            " variant="flat-primary" class="btn-icon" v-b-tooltip.hover.top="'Update Insurance'"
              @click="ChangeBasics(data.item.id, 'uInsurance')">
              <feather-icon icon="ToolIcon" />
            </b-button>
            <b-button v-if="
              isOpenThisVisit(data.item.office_visit.visit_statuses_id) &&
              data.item.is_insurance_appointment &&
              $can('appointment_transform_to_self_pay', 'module_patient.patient')
            " variant="flat-primary" class="btn-icon" v-b-tooltip.hover.top="'Transform to self-pay'"
              @click="toSelfPay(data.item.id)">
              <feather-icon icon="ZapIcon" />
            </b-button>

            <b-button v-if="
              data.item.office_visit.omas &&
              data.item.office_visit.visit_statuses_id != 6 &&
              $can('change_oma', 'module_patient.patient')
            " variant="flat-primary" class="btn-icon" v-b-tooltip.hover.top="'Change OMA'"
              @click="changeOMA(data.item)">
              <feather-icon icon="UserPlusIcon" />
            </b-button>

            <b-button
              v-if="data.item.office_visit.visit_statuses_id != 6 && $can('change_facility', 'module_patient.patient')"
              variant="flat-primary" class="btn-icon" v-b-tooltip.hover.top="'Change Facility'"
              @click="changeFacility(data.item)">
              <feather-icon icon="HomeIcon" />
            </b-button>
            <b-button v-if="
              data.item.office_visit.visit_statuses_id != 5 &&
              data.item.office_visit.visit_statuses_id != 6 &&
              $can('appointment_delete', 'module_patient.patient')
            " variant="flat-danger" class="btn-icon" v-b-tooltip.hover.top="'Delete'"
              @click="deleteAppointment(data.item.id)">
              <feather-icon icon="TrashIcon" />
            </b-button>
            <!-- reopen visit -->
            <b-button v-if="
              data.item.office_visit.visit_statuses_id === 5 &&
              data.item.office_visit.visit_statuses_id != 6 &&
              $can('appointment_reopen', 'module_patient.patient')
            " variant="flat-dark" class="btn-icon" v-b-tooltip.hover.top="'Re-open visit'"
              @click="reopenAppointment(data.item.id)">
              <feather-icon icon="RewindIcon" />
            </b-button>
            <!-- change type of visit -->
            <b-button v-if="data.item.office_visit.visit_statuses_id != 6 &&
              $can('appointment_reopen', 'module_patient.patient')" variant="flat-primary" class="btn-icon"
              v-b-tooltip.hover.top="'Change type of visit'" @click="changeTypeVisit(data.item)">
              <feather-icon icon="RotateCcwIcon" />
            </b-button>
            <b-button v-if="data.item.office_visit.visit_statuses_id != 6 &&
              $can('appointment_reopen', 'module_patient.patient')" variant="flat-primary" class="btn-icon"
              v-b-tooltip.hover.top="'Change visit type to online'" @click="changeTypeVisitOnline(data.item.id)">
              <feather-icon icon="RotateCwIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </div>
    <ChangeBasics v-if="conditionChangeBasics" :appointmentById="appointmentById" :methodCB="methodCB"
      @closeModalPay="conditionChangeBasics = false" @NotificationCheck="NotificationCheck" />
    <ChangeFacility v-if="conditionFacilityAppointment" :appointmentById="appointmentById"
      @closeModalFacility="conditionFacilityAppointment = false" @NotificationCheck="NotificationCheck" />
    <RefundAppointment v-if="conditionRefund" :appointmentById="appointmentById"
      @closeModalPay="conditionRefund = false" @NotificationCheck="NotificationCheck" />
    <ChangeOma v-if="conditionOmaAppointment" :appointmentById="appointmentById"
      @closeModalFacility="conditionOmaAppointment = false" @NotificationCheck="NotificationCheck" />
    <ChangeTypeOfVisit v-if="conditionchangeVisitAppointment" :dataAppointment="appointmentById"
      @closeModalFacility="conditionchangeVisitAppointment = false" @NotificationCheck="NotificationCheck" />
    <ToastNotification ref="toast" />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BTabs,
  BTab,
  BFormCheckbox,
  BCol,
  BRow,
  BButton,
  BMedia,
  BAvatar,
  BBadge,
  BLink,
  VBTooltip,
} from "bootstrap-vue";

import axiosA from "@/services/appointment/actions";

import NotificationMixin from "@/mixins/NotificationMixin";

import ToastNotification from "@/components/ToastNotification";
import ChangeFacility from "./actions/ChangeFacility.vue";
import ChangeOma from "./actions/ChangeOma.vue";
import ChangeTypeOfVisit from "./actions/ChangeTypeOfVisit.vue";
import RefundAppointment from "./actions/RefundAppointment";
import RecipePatient from "@/views/appointment/appointment-recipes/RecipePatient.vue";
import RecipeInsurancePayment from "@/views/appointment/appointment-recipes/RecipeInsurancePayment.vue";
import PatientsAppointmentView from "@/views/patients/patients-view/PatientsAppointmentView.vue";
import ChangeBasics from "./actions/changeBasics.vue";
export default {
  components: {
    BCard,
    ChangeBasics,
    BCardHeader,
    BCardTitle,
    BTable,
    BTabs,
    BTab,
    BFormCheckbox,
    BCol,
    BRow,
    BButton,
    BMedia,
    BAvatar,
    BBadge,
    BLink,
    ToastNotification,
    ChangeFacility,
    ChangeOma,
    ChangeTypeOfVisit,
    RefundAppointment,
    RecipePatient,
    RecipeInsurancePayment,
    PatientsAppointmentView,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    appointmentData: {
      type: Array,
      default: () => { },
      required: true,
    },
    userData: {
      type: Object,
      default: () => { },
      required: true,
    },
  },
  mixins: [NotificationMixin],
  data() {
    return {
      fields: [
        { key: "show_details", class: "th-class-adjusted" },
        { key: "id", sortable: true, class: "th-class-adjusted" },
        {
          key: "type_visits",
          label: "Type Visit",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "visit_status",
          label: "Visit Status",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "visit_date",
          label: "Visit Date",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "type_consultations",
          label: this.$t("Visit Reason"),
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "facilities",
          label: "Facility",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "frontdesks",
          label: "Frontdesk",
          sortable: true,
          class: "th-class-adjusted",
        },
        { key: "actions", class: "th-class-adjusted" },
      ],
      conditionFacilityAppointment: false,
      conditionChangeBasics: false,
      conditionOmaAppointment: false,
      conditionchangeVisitAppointment: false,
      conditionRefund: false,
      appointmentById: null,
      methodCB: "",
      copay: null,
    };
  },
  methods: {
    isOpenThisVisit(visit_statuses_id) {
      return visit_statuses_id != 5 &&
        visit_statuses_id != 6 &&
        visit_statuses_id != 7
    },
    getStatus(item, param) {
      return item.visit_statuses ? item.visit_statuses[param] : ""
    },
    changeCopay(item) {
      this.copay = item;
    },
    changeDetails(item) {
      item.toggleDetails();
      //cerrar detalles de todos los demas
      this.appointmentData.forEach((element) => {
        if (element.id != item.item.id) {
          element._showDetails = false;
        }
      });
    },
    deleteAppointment(item) {
      const data = {
        id: item,
      };
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosA.appointmentDelete(data).then((res) => {
            const { type, message } =
              NotificationMixin.methods.notificationCheck(res);
            this.NotificationCheck({ type, message });
            this.$emit("patientAppointment");
          });
        }
      });
    },
    reopenAppointment(item) {
      const data = {
        id: item,
      };
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Reopen appointment",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosA.appointmentReOpen(data).then((res) => {
            const { type, message } =
              NotificationMixin.methods.notificationCheck(res);
            this.NotificationCheck({ type, message });
            this.$emit("patientAppointment");
          });
        }
      });
    },
    changeOMA(item) {
      this.conditionOmaAppointment = true;
      const infoOma = item.office_visit.omas;
      const dataOma = {
        ...infoOma,
        idAppointment: item.id,
        fullname: `${infoOma.name} ${infoOma.lastname}`,
      };
      this.appointmentById = dataOma;
    },
    changeFacility(item) {
      this.conditionFacilityAppointment = true;
      this.appointmentById = item;
    },
    ChangeBasics(item, method) {
      this.conditionChangeBasics = true;
      this.appointmentById = item;
      this.methodCB = method
    },
    changeTypeVisit(item) {
      this.conditionchangeVisitAppointment = true;
      this.appointmentById = item;
    },
    changeTypeVisitOnline(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, change visit to online",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosA.appointmentChangeTypeOfVisitOnline(item).then((res) => {
            const { type, message } =
              NotificationMixin.methods.notificationCheck(res);
            this.NotificationCheck({ type, message });
            this.$emit("patientAppointment");
          });
        }
      });
    },
    toSelfPay(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You are going to transform this visit to a self pay",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, change visit to self pay",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosA.appointmentChangePayment(item).then((res) => {
            const { type, message } =
              NotificationMixin.methods.notificationCheck(res);
            this.NotificationCheck({ type, message });
            this.$emit("patientAppointment");
          });
        }
      });
    },
    refundAppointment(item) {
      this.conditionRefund = true;
      this.appointmentById = item;
    },
    NotificationCheck({ type, message }) {
      this.$refs.toast[type](message);
      this.$emit("patientAppointment");
    },
  },
};
</script>

<style lang="scss">
.th-class-adjusted {
  padding: 0.9rem 0.1rem !important;
}

.b-table-details {
  background-color: #fafafc;
}
</style>
