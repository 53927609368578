import { userAxios } from '../index'

const historyList = async (id) => {
    try {
        return await userAxios.get(`patients/history/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    historyList
}