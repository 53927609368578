<template>
  <b-modal
    id="cost"
    v-model="conditionRefund"
    hide-footer
    hide-header
    centered
    content-class="transparent"
    size="md"
    modal-class="modal-danger text-center"
    title="Danger Modal"
    ref="CostModal"
    @hide="onHide"
  >
    <b-card
      align="center"
      class="position-static"
      body-border-variant="primary"
    >
      <b-table
        :items="dataRefund.refunds"
        :fields="fields"
        striped
        bordered
        responsive
        class="position-relative text-center patient-appoinment"
        hover
      >
      </b-table>

      <b-button variant="primary" @click="onHide()">OK</b-button>
    </b-card>
  </b-modal>
</template>

<script>
import { BModal, BCard, BButton, BTable } from "bootstrap-vue";
export default {
  components: {
    BModal,
    BCard,
    BButton,
    BTable,
  },
  props: {
    dataRefund: {
      type: Object,
    },
  },
  data() {
    return {
      conditionRefund: true,
      fields: [{ key: "id", sortable: true }, { key: "note" }, { key: "value" }],
    };
  },
  methods: {
    onHide() {
      this.$emit("closeModal", false);
    },
  },
};
</script>

<style lang="scss">
.transparent {
  box-shadow: none !important;
  background-color: transparent !important;
}
</style>
