<template>
  <b-card class="text-center ml-1" v-if="appointmentProcedure.length > 0" body-class="py-1 p-0" style="width: 290px;">
    <h4>{{ title }}</h4>
    <b-badge
      variant="primary"
      :class="index == 0 ? 'mr-1' : ''
      "
      v-for="(procedure, index) in appointmentProcedure"
      :key="procedure.id"
    >
      {{ procedure.name }}
    </b-badge>
  </b-card>
</template>

<script>
import { BCard, BBadge } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BBadge,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    appointmentProcedure: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
