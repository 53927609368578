<template>
  <b-modal v-model="conditionCancel" @hide="onHide" hide-footer hide-header centered size="md">
    <validation-observer #default="{ handleSubmit }">
      <b-form class="auth-login-form mt-2" @submit.prevent="handleSubmit(onSubmitCancel)" v-if="dataAppointment">
        <!-- total -->
        <validation-provider #default="{ errors }" name="Total">
          <b-form-group label="Total" label-for="total">
            <b-form-input id="total" name="total" disabled v-model="dataAppointment.true_total"
              :state="errors.length > 0 ? false : null" />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider #default="{ errors }" name="Type" rules="required">
          <b-form-group label="Type" label-for="type" :state="errors.length > 0 ? false : null" class="my-1">
            <v-select id="type" v-model="formRefund.type" :options="typeOptions" :reduce="(value) => value.id"
              label="name" />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <b-row>
          <b-col>
            <validation-provider #default="{ errors }" name="cash" :rules="{
              totalRules: {
                required: true,
                totalPay: splitPay,
                total: dataAppointment.total
              }
            }">
              <b-form-group label="Cash" label-for="cash" :invalid-feedback="validateTotal">
                <b-form-input id="cash" name="cash" v-model="formRefund.cash.value"
                  :state="errors.length > 0 ? false : null" type="number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider #default="{ errors }" name="Transaction Id" rules="required">
              <b-form-group label="Cash Transaction Id" label-for="Transaction Id">
                <b-form-input id="transaction_id_cash" name="Transaction Id" :disabled="!formRefund.cash.value"
                  v-model="formRefund.cash.transaction_id" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <validation-provider #default="{ errors }" name="Credit card" :rules="{
              totalRules: {
                required: true,
                totalPay: splitPay,
                total: dataAppointment.total
              }
            }">
              <b-form-group label="Credit card" label-for="Credit card" :invalid-feedback="validateTotal">
                <b-form-input id="Credit_card" name="Credit card" v-model="formRefund.cc.value"
                  :state="errors.length > 0 ? false : null" type="number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider #default="{ errors }" name="CC Transaction ID" rules="required">
              <b-form-group label="CC Transaction ID" label-for="CC Transaction ID">
                <b-form-input id="cc_transaction_id" name="CC Transaction ID" :disabled="!formRefund.cc.value"
                  v-model="formRefund.cc.transaction_id" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- internal payment note-->
        <validation-provider #default="validationContext" name="Note" rules="required">
          <b-form-group label="Note" label-for="internal-payment-note" class="my-1">
            <b-form-textarea id="internal-payment-note" v-model="formRefund.note" placeholder="Internal payment note"
              no-resize rows="3" :class="{
                'is-invalid':
                  validationContext.touched && !validationContext.valid,
              }" />
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <div class="d-flex justify-content-end mt-2">
          <b-button type="button" class="mr-2" variant="outline-danger" @click="onHide">
            {{ $t("Cancel") }}
          </b-button>
          <b-button variant="warning" type="submit" :disabled="loading">
            <span v-if="!loading">Send</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTextarea,
  BCol,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

import SpinnerLoading from "@/components/SpinnerLoading";

import axiosOA from "@/services/appointment/actions";
import axiosA from "@/services/appointment";

import NotificationMixin from "@/mixins/NotificationMixin";

export default {
  components: {
    BModal,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,

    flatPickr,
    vSelect,

    ValidationProvider,
    ValidationObserver,

    SpinnerLoading,
  },
  props: {
    appointmentById: {
      type: Number,
      required: true,
    },
  },
  mixins: [NotificationMixin],
  data() {
    return {
      conditionCancel: true,
      conditionSplit: true,
      loading: false,
      formRefund: {
        note: null,
        type: null,
        cash: {
          value: 0,
          transaction_id: null,
        },
        cc: {
          value: 0,
          transaction_id: null,
        },
      },
      dataAppointment: null,
      typeOptions: [
        {
          name: "Appointment",
          id: "appointment",
        },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    splitPay() {
      return parseFloat(this.formRefund.cash.value) + parseFloat(this.formRefund.cc.value);
    },
    validateTotal() {
      extend("totalRules", {
        params: ["total", "totalPay"],
        validate: (value, { total, totalPay }) => {
          // quitar este return cuando se tenga con que validar
          return true;
          if (totalPay == parseFloat(total)) {
            return true;
          } else {
            return false;
          }
        },
        message: (field, { total }) =>
          `El ${field} must be under or greater to ${total}`,
      });
    },
  },
  watch: {
    formRefund: {
      handler() {
        this.dataAppointment.true_total = this.getTrueTotal()
      },
      deep: true
    }
  },
  methods: {
    onHide() {
      this.$emit("closeModalPay", false);
    },
    getTrueTotal() {
      return this.dataAppointment.is_insurance_appointment == true && this.formRefund.type == 'copay' ? this.dataAppointment.copay : this.dataAppointment.total
    },
    getData() {
      axiosA
        .appointmentDetail(this.appointmentById)
        .then(({ registro: { appointment } }) => {
          this.dataAppointment = appointment;
          this.dataAppointment.true_total = this.getTrueTotal()
          if (appointment.copay) this.typeOptions.push({
            name: "Copay",
            id: "copay",
          },)
        });
    },
    async onSubmitCancel() {
      this.loading = true;
      const data = {
        refund: {
          ...this.formRefund,
        }
      };
      await axiosOA
        .appointmentRefundsV2(this.appointmentById, data)
        .then((res) => {
          const { type, message } =
            NotificationMixin.methods.notificationCheck(res);
          this.loading = false;
          this.$emit("paymentChecked", true);
          this.$emit("NotificationCheck", { type, message });
        })
        .catch((e) => {
          this.loading = false;
        });
      this.$emit("closeModalPay", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
