<template>
  <div>
    <section class="invoice-preview-wrapper">
      <b-row v-if="amountPayment" class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" xl="9" md="8">
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Header: Left Content -->
                <div class="w-50">
                  <div class="logo-wrapper">
                    <img
                      src="@/assets/images/logo/guadalupe.png"
                      width="80%"
                      class="img-fluid"
                      alt="logo"
                      srcset=""
                    />
                  </div>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-2 d-flex">
                  <hr class="separator-vertical" />
                  <div class="w-100">
                    <div class="card-text mb-1 d-flex text-break">
                      <feather-icon
                        icon="MapIcon"
                        class="cursor-pointer mx-1 text-primary"
                        size="20"
                      />
                      <span>
                        {{ facility.adress }}
                      </span>
                    </div>
                    <p class="card-text mb-1 d-flex text-break">
                      <feather-icon
                        icon="PhoneIcon"
                        class="cursor-pointer mx-1 text-primary"
                        size="20"
                      />
                      <span> +1 {{ facility.phone }} </span>
                    </p>
                    <div class="card-text mb-1 d-flex text-break">
                      <feather-icon
                        icon="MailIcon"
                        class="cursor-pointer mx-1 text-primary"
                        size="20"
                      />
                      <span>
                        {{ facility.email }}
                      </span>
                    </div>
                    <div class="card-text mb-1 d-flex text-break">
                      <feather-icon
                        icon="HomeIcon"
                        class="cursor-pointer mx-1 text-primary"
                        size="20"
                      />
                      <a
                        class="card-text mb-1"
                        href="https://www.clinicavirgenguadalupe.com"
                        target="_blank"
                      >
                        www.clinicavirgenguadalupe.com
                      </a>
                    </div>
                  </div>
                  <!-- <div class="go-back">
                  <GoBack />
                </div> -->
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <img
              src="@/assets/images/illustration/separador.png"
              alt=""
              srcset=""
            />
            <!-- Invoice Client & Payment Details -->
            <b-card-body v-if="amountPayment" class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col md="6" class="p-0">
                  <h5 class="mb-2">{{ $t("Invoice to") }}:</h5>
                  <h4 class="mb-2 text-primary">
                    {{ amountPayment.name }} {{ amountPayment.lastname }}
                  </h4>
                  <p class="card-text mb-25">
                    <span class="font-weight-bold">DOB</span>:
                    {{ amountPayment.date_birth }}
                  </p>
                  <p class="card-text mb-25">
                    <span class="font-weight-bold">P</span>: {{ formatPhone }}
                  </p>
                  <p class="card-text mb-0">
                    {{ amountPayment.companyEmail }}
                  </p>
                </b-col>
                <b-col md="6" class="p-0">
                  <h1 class="text-uppercase">{{ $t("Invoice") }}</h1>
                  <hr class="invoice-spacing" />
                  <div class="d-flex justify-content-center">
                    <div class="w-50">
                      <p>{{ $t("Total Due") }}:</p>
                      <h4 class="font-weight-bold">
                        $ {{ amountPayment.payment }}
                      </h4>
                    </div>
                    <hr class="separator-vertical-md" />
                    <div class="w-50 ml-2">
                      <p>{{ $t("Invoice Date") }}:</p>
                      <h5 class="font-weight-bold">{{ dateIssued }}</h5>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Invoice Description: Table -->
            <b-container fluid>
              <b-table
                id="table-amountPayment-items"
                responsive
                :items="amountPaymentDescription"
                :fields="fields"
                striped
              >
                <template #head(name)="data">
                  <span> {{ $t(data.label) }}</span>
                </template>
                <template #head(quantity)="data">
                  <span> {{ $t(data.label) }}</span>
                </template>
                <template #head(price)="data">
                  <span> {{ $t(data.label) }}</span>
                </template>
                <template v-slot:custom-foot>
                  <tr>
                    <td class="" :colspan="2">Total</td>
                    <td class="" :colspan="2">{{ amountPayment.payment }}</td>
                  </tr>
                </template>
              </b-table>
            </b-container>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
                >
                  <b-card-text class="mb-0">
                    <!-- <span class="font-weight-bold">Info:</span> -->
                  </b-card-text>
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper w-100">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Convenience Fee:</p>
                      <p class="invoice-total-amount">
                        $ {{ amountPayment.convenience_fee }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Subtotal:</p>
                      <p class="invoice-total-amount">
                        $ {{ amountPayment.payment }}
                      </p>
                    </div>
                    <hr class="my-50" />
                    <div
                      class="invoice-total-item text-white bg-primary px-2 py-1"
                    >
                      <p class="invoice-total-title text-uppercase">Total:</p>
                      <p class="invoice-total-amount">
                        $ {{ (amountPayment.convenience_fee + amountPayment.payment) }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12" lg="6">
                  <b-card-text class="mb-0">
                    <span class="font-weight-bold">Terms & Conditions:</span>
                    <p>
                      Note: For questions concerning this invoice please contact
                      Front Desk Manager / (469) 606-0753 /
                      customerservice@lvdg.us
                    </p>
                  </b-card-text>
                </b-col>
              </b-row>
              <hr class="invoice-spacing" />
              <b-row class="align-items-center">
                <b-col cols="12" md="6">
                  <b-card-text class="mb-0">
                    <span class="font-weight-bold text-uppercase"
                      >thank you for your purchase</span
                    >
                  </b-card-text>
                </b-col>
                <b-col cols="12" md="6">
                  <img
                    class="float-right"
                    src="@/assets/images/logo/isotipo-logo.svg"
                    alt="logo alt"
                    width="10%"
                  />
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing-hr" />
          </b-card>
        </b-col>
        <b-col cols="12" md="4" xl="3" class="invoice-actions">
          <b-card>
            <!-- Button: DOwnload -->
            <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Download
          </b-button> -->
            <!-- Button: Print -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-primary"
              class="mb-75"
              block
              @click="printInvoice"
            >
              Print
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BContainer,
  BTable,
  BTfoot,
  BTh,
  BTr,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import GoBack from "@/components/ButtonBack.vue";
import Ripple from "vue-ripple-directive";
import { $themeColors } from "@themeConfig";
export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BContainer,
    BTable,
    BTfoot,
    BTh,
    BTr,
    BCardText,
    BButton,
    BAlert,
    BLink,

    Logo,
    GoBack,
  },
  created() {
    if (!this.isPatientView) {
      setTimeout(() => {
        this.printInvoice();
      }, 2000);
    }
  },
  mounted() {
    //cerrar la pestaña despues de dar click en imprimir
    if (!this.isPatientView) {
      window.onafterprint = function () {
        window.close();
      };
    }
  },
  props: {
    isPatientView: {
      type: Boolean,
      default: false,
    },
    dataPatient: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    dateIssued() {
      return new Date().toLocaleDateString();
    },
    formatPhone: {
      get() {
        let { phone } = this.amountPayment;
        phone = phone.toString().replace(/\D/g, "");
        const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
          phone = `(${match[1]}${match[2] ? "" : ""}) ${match[2]}${
            match[3] ? "-" : ""
          }${match[3]}`;
        }
        return phone;
      },
      set(val) {
        this.patient.phone = val;
      },
    },
  },
  data() {
    let recipeInfo = null;
    if (this.isPatientView) {
      recipeInfo = this.dataPatient;
    } else {
      recipeInfo = JSON.parse(atob(this.$route.query.data));
    }
    return {
      amountPayment: recipeInfo,
      facility: JSON.parse(atob(localStorage.getItem("Facility"))),
      fields: [
        {
          key: "name",
          label: "Name",
          class: ["th-class-invoice-patient", "test"],
          thStyle: {
            color: "white",
            clipPath: "polygon(0 0, 95% 0, 100% 100%, 0% 100%)",
            backgroundColor: $themeColors.primary,
          },
        },
        {
          key: "quantity",
          label: "Quantity",
          class: "th-class-invoice-patient",
          thStyle: {
            color: "white",
            clipPath: "polygon(5% 100%, 0 0, 100% 0, 100% 100%)",
            backgroundColor: "#333",
          },
        },
        {
          key: "price",
          label: "Price",
          class: "th-class-invoice-patient",
          thStyle: {
            color: "white",
            backgroundColor: "#333",
            clipPath: "polygon(0% 100%, 0 0, 100% 0, 100% 100%)",
          },
        },
      ],
      amountPaymentDescription: [
        {
          name: "Co-payment",
          quantity: 1,
          price: recipeInfo.payment,
        },
      ],
    };
  },
  methods: {
    printInvoice() {
      window.print();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
.invoice-spacing-hr {
  border: 0;
  height: 20px;
  background: url("https://newtodesign.com/wp-content/uploads/2018/09/Divider-css.png")
    0 0;
}
.separator-vertical {
  border: none;
  border-left: 2px solid hsla(200, 10%, 50%, 100);
  margin-top: 0;
  height: 15vh;
  width: 1px;
}
.separator-vertical-md {
  border: none;
  border-left: 2px solid hsla(200, 10%, 50%, 100);
  margin-top: 0;
  height: 7vh;
  width: 1px;
}
</style>

<style lang="scss">
@import "bootstrap/scss/functions";
@import "~@core/scss/base/bootstrap-extended/variables";
@import "bootstrap/scss/variables";
@import "~@core/scss/base/components/variables-dark";
.th-class-invoice-patient {
  width: 33%;
}
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .table th {
    background-color: $primary !important;
  }
  .main-menu {
    display: none;
  }
  .go-back {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  .separator-vertical {
    height: 9vh !important;
  }
  .separator-vertical-md {
    height: 4vh !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>