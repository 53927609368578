<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="!patient">
      <h4 class="alert-heading">Error fetching invoice data</h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link class="alert-link" to="/">
          <!-- :to="{ name: 'apps-invoice-list'}" -->
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert>

    <b-row v-if="patient" class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body v-if="!AppointmentId" class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div class="w-50">
                <div class="logo-wrapper">
                  <img
                    src="@/assets/images/logo/guadalupe.png"
                    width="80%"
                    class="img-fluid"
                    alt="logo"
                    srcset=""
                  />
                </div>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-2 d-flex">
                <hr class="separator-vertical" />
                <div class="w-100">
                  <div class="card-text mb-1 d-flex text-break">
                    <feather-icon
                      icon="MapPinIcon"
                      class="cursor-pointer mx-1 text-primary"
                      size="20"
                    />
                    <span>
                      {{ facility.address }}
                    </span>
                  </div>
                  <p class="card-text mb-1 d-flex text-break">
                    <feather-icon
                      icon="PhoneIcon"
                      class="cursor-pointer mx-1 text-primary"
                      size="20"
                    />
                    <span> +1 {{ facility.phone }} </span>
                  </p>
                  <div class="card-text mb-1 d-flex text-break">
                    <feather-icon
                      icon="MailIcon"
                      class="cursor-pointer mx-1 text-primary"
                      size="20"
                    />
                    <span>
                      {{ facility.email }}
                    </span>
                  </div>
                  <div class="card-text mb-1 d-flex text-break">
                    <feather-icon
                      icon="GlobeIcon"
                      class="cursor-pointer mx-1 text-primary"
                      size="20"
                    />
                    <a
                      class="card-text mb-1"
                      href="https://www.clinicavirgenguadalupe.com"
                      target="_blank"
                    >
                      www.clinicavirgenguadalupe.com
                    </a>
                  </div>
                </div>
                <div class="go-back">
                  <GoBack />
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <img
            src="@/assets/images/illustration/separador.png"
            alt=""
            srcset=""
          />

          <!-- Invoice Client & Payment Details -->
          <b-card-body v-if="patient" class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col md="6" class="p-0">
                <h5 class="mb-2">{{ $t("Invoice to") }}:</h5>
                <h4 class="mb-2 text-primary">
                  {{ patient.full_name }}
                </h4>
                <p class="card-text mb-25">
                  <span class="font-weight-bold">DOB</span>:
                  {{ patient.birthdate }}
                </p>
                <p class="card-text mb-25">
                  <span class="font-weight-bold">P</span>: {{ formatPhone(patient.phone) }}
                </p>
              </b-col>
              <b-col md="6" class="p-0">
                <h1 class="text-uppercase">{{ $t("Invoice") }}</h1>
                <p class="mb-25">
                  <span>NO: #{{ appointmentId }}</span>
                </p>
                <hr class="invoice-spacing" />
                <div class="d-flex justify-content-center">
                  <div class="w-50">
                    <p>{{ $t("Total Due") }}:</p>
                    <h4 class="font-weight-bold">
                      $ {{ totalPrice.value }}
                    </h4>
                  </div>
                  <hr class="separator-vertical-md" />
                  <div class="w-50 ml-2">
                    <p>{{ $t("Invoice Date") }}:</p>
                    <h5 class="font-weight-bold">{{ visitDate }}</h5>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-container fluid>
            <b-table
              id="table-patient-items"
              responsive
              :items="recipeDescription"
              :fields="fields"
              striped
            >
              <template #head(name)="data">
                <span> {{ $t(data.label) }}</span>
              </template>
              <template #head(quantity)="data">
                <span> {{ $t(data.label) }}</span>
              </template>
              <template #head(price)="data">
                <span> {{ $t(data.label) }}</span>
              </template>
              <template v-slot:custom-foot>
                <tr>
                  <td class="" :colspan="2">Total</td>
                  <td class="" :colspan="2">{{ subTotalProcedure.value }}</td>
                </tr>
              </template>
            </b-table>
          </b-container>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <img
                    :src="
                      require('@/assets/images/banner/banner_recipev2.webp')
                    "
                    width="100%"
                  />
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper w-100">
                  <div
                    v-for="(total, index) in totalsRecipe"
                    :key="index"
                    class="invoice-total-item"
                  >
                    <div v-if="total.name === 'total'" class="w-100">
                      <hr class="my-50" />
                      <div
                        class="invoice-total-item text-white bg-primary px-2 py-1"
                      >
                        <p class="invoice-total-title text-uppercase">Total:</p>
                        <p class="invoice-total-amount">$ {{ total.value }}</p>
                      </div>
                    </div>
                    <template v-else>
                      <p class="invoice-total-title">{{ total.name }}:</p>
                      <p class="invoice-total-amount" :class="{ 'text-danger': total.discount }">$ {{ total.value }}</p>
                    </template>
                  </div>
                  <!-- <div
                    class="invoice-total-item cursor-pointer"
                    @click="openRefund"
                    v-for="(item, index) in recipe.appointments.refunds"
                    :key="index"
                  >
                    <p class="invoice-total-title">Refund:</p>
                    <p class="invoice-total-amount text-danger">
                      - $ {{ item.value }}
                    </p>
                  </div> -->
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="6">
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">Terms & Conditions:</span>
                  <p>
                    Note: For questions concerning this invoice please contact
                    Front Desk Manager / (469) 606-0753 /
                    customerservice@lvdg.us
                  </p>
                </b-card-text>
              </b-col>
            </b-row>
            <hr class="invoice-spacing" />
            <b-row class="align-items-center">
              <b-col cols="12" md="6">
                <b-card-text class="mb-0">
                  <span class="font-weight-bold text-uppercase"
                    >thank you for your purchase</span
                  >
                </b-card-text>
              </b-col>
              <b-col cols="12" md="6">
                <Logo class="float-right" width="10%" />
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing-hr" />
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <!-- Button: DOwnload -->
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Download
          </b-button> -->
          <router-link
            v-if="AppointmentId"
            :to="{ name: 'recipe-patient', params: { recipeId: AppointmentId } }"
          >
            <feather-icon
              icon="PrinterIcon"
              class="cursor-pointer mx-1"
              size="16"
            />See more</router-link
          >
          <!-- Button: Print -->
          <b-button
            v-else
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
            @click="printInvoice(patient.full_name, appointmentId)"
          >
            Print
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="downloadInvoice"
          >
            Download
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <RefundView
      :dataRefund="dataRefund"
      v-if="isViewRefund"
      @closeModal="(isViewRefund = null), (dataRefund = null)"
    />
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BContainer,
  BTable,
  BTfoot,
  BTh,
  BTr,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import GoBack from "@/components/ButtonBack.vue";

import Ripple from "vue-ripple-directive";
import { formatPhone } from "@/mixins/formatPhone.js";

import RefundView from "../refund/RefundView.vue";

import axiosOV from "@/services/office-visit";
import axiosAR from "@/services/appointment/refund";
import axiosRecipe from "@/services/office-visit/recipe";

import { $themeColors } from "@themeConfig";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BContainer,
    BTable,
    BTfoot,
    BTh,
    BTr,
    BCardText,
    BButton,
    BAlert,
    BLink,
    RefundView,

    Logo,
    GoBack,
  },
  data() {
    return {
      formatPhone,
      fields: [
        {
          key: "name",
          label: "Name",
          class: ["th-class-invoice-patient", "test"],
          thStyle: {
            color: "white",
            clipPath: "polygon(0 0, 95% 0, 100% 100%, 0% 100%)",
            backgroundColor: $themeColors.primary,
          },
        },
        {
          key: "quantity",
          label: "Quantity",
          class: "th-class-invoice-patient",
          thStyle: {
            color: "white",
            clipPath: "polygon(5% 100%, 0 0, 100% 0, 100% 100%)",
            backgroundColor: "#333",
          },
        },
        {
          key: "price",
          label: "Price",
          class: "th-class-invoice-patient",
          thStyle: {
            color: "white",
            backgroundColor: "#333",
            clipPath: "polygon(0% 100%, 0 0, 100% 0, 100% 100%)",
          },
        },
      ],
      recipe: null,
      patient: null,
      facility: null,
      recipeDescription: null,
      isViewRefund: false,
      dataRefund: null,
      totalsRecipe: null,
      subTotalPrice: null,
      subTotalProcedure: null,
      visitDate: null,
      appointmentId: null,
      totalPrice: null
    };
  },
  props: {
    AppointmentId: {
      type: Number,
      required: false,
    },
    isReview: {
      type: Boolean,
      required: false,
    },
  },
  mounted() {
    this.getRecipe();
  },
  methods: {
    getRecipe() {
      this.appointmentId = this.AppointmentId ? this.AppointmentId : this.$route.params.recipeId
      axiosRecipe
        .getRecipe(this.appointmentId)
        .then(({ registro: { copay, cupon_data, procedures, total, facility_data, patient_data, visit_date } }) => {
          this.recipeDescription = procedures;
          const newTotal = total
          if(cupon_data) {
            const indexCampaign = newTotal.findIndex(i => i.name == "Adjustment")
            cupon_data.value = cupon_data.type == "value" ? cupon_data.value : `${cupon_data.value}%`
            cupon_data.discount = true
            newTotal[indexCampaign] = cupon_data
          }
          this.totalsRecipe = newTotal;
          this.subTotalPrice = total.find((item) => item.name === 'Subtotal')
          this.subTotalProcedure = total.find((item) => item.name === 'Procedures total')
          this.totalPrice = total.find((item) => item.name == 'total')
          this.facility = facility_data
          this.patient = patient_data
          this.visitDate = visit_date
          if (this.isReview && copay && copay > 0) {
            this.$emit("copayIsApplied", copay);
          } else {
            this.$emit("copayIsApplied", null);
          }
        });
      // axiosOV
      //   .officeVisitDetailt(this.AppointmentId || this.$route.params.id)
      //   .then(({ office_visit }) => {
      //     this.recipe = office_visit;
      //     const { appointments } = office_visit;

      //     const {
      //       copay,
      //     } = appointments;

      //     if (this.isReview && copay > 0) {
      //       this.$emit("copayIsApplied", copay);
      //     } else {
      //       this.$emit("copayIsApplied", null);
      //     }
      //   });
    },
    printInvoice(nameComplete, recipeId) {
      setTimeout(function () {
        document.title = `${nameComplete.split(" ").join("-")}-${recipeId}`;

        // Ejecuta la función de guardar como PDF
        window.print();
        setTimeout(function () {
          document.title = "Clinic 360";
        }, 1000);
      }, 1000);
    },
    downloadInvoice() {
      console.log(window.print());
    },
    openRefund() {
      axiosAR
        .appointmentRefundView(this.AppointmentId || this.$route.params.recipeId)
        .then(({ registro }) => {
          this.dataRefund = registro;
          this.isViewRefund = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
.invoice-spacing-hr {
  border: 0;
  height: 20px;
  background: url("https://newtodesign.com/wp-content/uploads/2018/09/Divider-css.png")
    0 0;
}
.separator-vertical {
  border: none;
  border-left: 2px solid hsla(200, 10%, 50%, 100);
  margin-top: 0;
  height: 15vh;
  width: 1px;
}
.separator-vertical-md {
  border: none;
  border-left: 2px solid hsla(200, 10%, 50%, 100);
  margin-top: 0;
  height: 7vh;
  width: 1px;
}
</style>

<style lang="scss">
@import "bootstrap/scss/functions";
@import "~@core/scss/base/bootstrap-extended/variables";
@import "bootstrap/scss/variables";
@import "~@core/scss/base/components/variables-dark";
.th-class-invoice-patient {
  width: 33%;
}
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .table th {
    background-color: $primary !important;
  }
  .main-menu {
    display: none;
  }
  .go-back {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  .separator-vertical {
    height: 9vh !important;
  }
  .separator-vertical-md {
    height: 4vh !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
