<template>
  <b-modal
    id="modal-lg"
    v-model="conditionTypeOfVisit"
    hide-footer
    hide-header
    centered
    size="lg"
    no-close-on-backdrop
    @hide="onHide"
  >
    <form @submit.prevent="onSubmitTypeOfVisit">
      <validation-provider
        #default="{ errors }"
        name="Type Of Visit"
        rules="required"
      >
        <b-form-group label="Type Of Visit" label-for="type_visit">
          <v-select
            id="type_visit"
            v-model="typeOfVisit"
            :options="optionsTypeOfVisit"
            :reduce="(option) => option.id"
            :clearable="false"
            label="name"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
      <div class="d-flex justify-content-end mt-2">
        <b-button
          type="button"
          class="mr-2"
          variant="outline-danger"
          @click="onHide"
        >
          {{ $t("Cancel") }}
        </b-button>
        <b-button variant="primary" type="submit" :disabled="loading">
          <span v-if="!loading">Change</span>
          <span v-else>
            <SpinnerLoading />
          </span>
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { BButton, BModal, BFormGroup } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import SpinnerLoading from "@/components/SpinnerLoading";
import NotificationMixin from "@/mixins/NotificationMixin";

import vSelect from "vue-select";

import axiosTypeOfVisit from "@/services/admin/consults/visits";
import axiosA from "@/services/appointment/actions";
export default {
  components: {
    BButton,
    BModal,
    BFormGroup,

    ValidationProvider,
    ValidationObserver,

    SpinnerLoading,
    vSelect,
  },
  props: {
    dataAppointment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      conditionTypeOfVisit: true,
      typeOfVisit: this.dataAppointment.type_visits_id,
      optionsTypeOfVisit: [],
      loading: false,
    };
  },
  mixins: [NotificationMixin],
  mounted() {
    this.getTypeOfVisit();
  },
  methods: {
    onHide() {
      this.$emit("closeModalFacility", false);
    },
    getTypeOfVisit() {
      axiosTypeOfVisit.visitList(50).then(({ data }) => {
        this.optionsTypeOfVisit = data;
      });
    },
    onSubmitTypeOfVisit() {
      this.loading = true;
      const data = {
        type_visits_id: this.typeOfVisit
      };
      axiosA.appointmentChangeTypeOfVisit(this.dataAppointment.id, data).then((res) => {
        this.loading = false;
        const { type, message } =
          NotificationMixin.methods.notificationCheck(res);
        this.$emit("NotificationCheck", { type, message });
        this.onHide();
      });
    },
  },
};
</script>

<style lang="scss"></style>
