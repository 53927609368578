<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error finding user PATIENT</h4>
      <div class="alert-body">
        No patient found with this patient id. Check
        <b-link class="alert-link" :to="{ name: 'patients' }">
          Patients List
        </b-link>
        for other patients.
      </div>
    </b-alert>
    <div class="d-flex flex-column align-items-end" style="height: 0;">
      <GoBack class="mb-2 button-back" />
    </div>
    <b-tabs pills>
      <!-- Tab: Account -->
      <b-tab active v-if="userData">
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <PatientsEditInformation :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Appointment -->
      <b-tab v-if="appointmentData && $can('show', 'module_patient.patient') && !isShow">
        <template #title>
          <feather-icon icon="Share2Icon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Resource Schedule</span>
        </template>
        <PatientsAppointments
          class="mt-2 pt-75"
          :userData="userData"
          :appointment-data="appointmentData"
          @patientAppointment="getAppointmentPatient"
        />
      </b-tab>

      <!-- Tab: Clinical Picture -->
      <b-tab v-if="historyData && $can('show', 'module_patient.patient') && !isShow">
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Clinical Picture</span>
        </template>
        <PatientsClinicalPicture class="mt-2 pt-75" :history-data="historyData" />
      </b-tab>

      <!-- Tab: membershipHistory -->
      <b-tab v-if="membershipHistory && membershipHistory.length && $can('show', 'module_patient.patient') && !isShow">
        <template #title>
          <feather-icon icon="TagIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Membership History</span>
        </template>
        <MembershipHistory class="mt-2 pt-75" :membership-history="membershipHistory" />
      </b-tab>

      <!-- Tab: Appointment -->
      <b-tab v-if="userData && userData.other_charges && $can('show', 'module_patient.patient') && !isShow">
        <template #title>
          <feather-icon icon="DollarSignIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Other Charges</span>
        </template>
        <PatientsOtherCharges
          class="mt-2 pt-75"
          :userData="userData.other_charges"
        />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import PatientsEditInformation from "./PatientsEditInformation.vue";
import PatientsClinicalPicture from "./PatientsClinicalPicture.vue";
import PatientsAppointments from "./PatientsAppointments.vue";
import MembershipHistory from "./MembershipHistory.vue";

import GoBack from "@/components/ButtonBack.vue";

import axiosP from "@/services/patient";
import axiosPH from "@/services/patient/history";
import axiosPA from "@/services/patient/appointment";
import PatientsOtherCharges from "./PatientsOtherCharges.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    MembershipHistory,
    GoBack,
    PatientsOtherCharges,
    PatientsEditInformation,
    PatientsClinicalPicture,
    PatientsAppointments,
  },
  setup() {
    const userData = ref(null);
    const historyData = ref(null);
    const membershipHistory = ref(null);
    const appointmentData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-user";

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    onMounted(() => {
      getAppointmentPatient();
    }),
      // Get user data
      axiosP
        .patientDetail(router.currentRoute.params.id)
        .then(({ registro }) => {
          userData.value = registro;
        });

    axiosPH.historyList(router.currentRoute.params.id).then(({ registro: { patient_history, membership_history } }) => {
      historyData.value = patient_history;
      membershipHistory.value = membership_history
    });

    const isShow = ref(router.currentRoute.params.isShow);
    const getAppointmentPatient = () => {
      axiosPA
        .appointmentList(router.currentRoute.params.id)
        .then(({ registro }) => {
          appointmentData.value = registro.data;
        });
    };

    /* store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      
      }
      *]
      .then(response => { userData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      }) */

    return {
      userData,
      historyData,
      appointmentData,
      getAppointmentPatient,
      membershipHistory,
      isShow,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
