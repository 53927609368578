<template>
  <ul v-bind="$attrs" class="timeline" v-on="$listeners">
    <slot />
  </ul>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.timeline {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
</style>
