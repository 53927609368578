var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"10"}},[_c('b-row',{attrs:{"md":"4","lg":"6"}},[_c('b-col',{staticClass:"d-flex justify-content-between",attrs:{"cols":"12"}},[_c('b-card',{staticClass:"w-75",attrs:{"body-class":"pt-1 p-0"}},[_c('statistic-card-vertical',{attrs:{"icon":"HomeIcon","statistic":_vm.appointmentDetail.item.office_visit.room,"statistic-title":'Room',"color":"primary"}})],1),_c('b-card',{staticClass:"text-center ml-1 w-75"},[_c('b-avatar',{staticClass:"mb-1",attrs:{"size":"30","src":_vm.appointmentDetail.item.office_visit.nurses.avatar}}),_c('div',{staticClass:"text-center d-flex flex-column"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("NURSE")]),_c('b-link',{attrs:{"to":{
                name: 'web-settings-users',
                params: { id: _vm.appointmentDetail.item.office_visit.nurses.id },
              }}},[_c('span',[_vm._v(" "+_vm._s(_vm.appointmentDetail.item.office_visit.nurses.name)+" "+_vm._s(_vm.appointmentDetail.item.office_visit.nurses.lastname)+" ")])])],1)],1),_c('b-card',{staticClass:"text-center ml-1 w-75"},[_c('b-avatar',{staticClass:"mb-1",attrs:{"size":"30","src":_vm.appointmentDetail.item.office_visit.omas.avatar}}),_c('div',{staticClass:"text-center d-flex flex-column"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("OMA")]),_c('b-link',{attrs:{"to":{
                name: 'web-settings-users',
                params: { id: _vm.appointmentDetail.item.office_visit.omas.id },
              }}},[_c('span',[_vm._v(" "+_vm._s(_vm.appointmentDetail.item.office_visit.omas.name)+" "+_vm._s(_vm.appointmentDetail.item.office_visit.omas.lastname)+" ")])])],1)],1),_c('b-card',{staticClass:"w-75 ml-1",attrs:{"body-class":"pt-1 p-0"}},[_c('statistic-card-vertical',{attrs:{"icon":"DollarSignIcon","statistic":_vm.appointmentDetail.item.total,"statistic-title":'Total',"color":"primary"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticClass:"card-transaction",attrs:{"title":"Vital signs","body-class":"pt-1 p-0"}},[_c('div',{staticClass:"d-flex justify-content-around"},_vm._l((_vm.appointmentDetail.item.office_visit
                .type_vital_sign),function(item,i){return _c('div',{key:i,attrs:{"lg":"4","md":"3","xl":"3"}},[_c('statistic-card-vertical',{attrs:{"icon":"ActivityIcon","statistic":item.pivot.value,"statistic-title":item.prefix,"color":"primary"}})],1)}),0)])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticClass:"card-transaction mb-1",attrs:{"title":"Visit Timeline","body-class":"pt-1 p-0","title-tag":"h6"}},[_c('AppTimelineHorizontal',_vm._l((_vm.appointmentDetail.item.office_visit
                .visit_status_auditor),function(item,i){return _c('AppTimelineHorizontalItem',{key:i,attrs:{"title":item.name,"sub-title":item.pivot.description,"time":item.pivot.created_at,"variant":item.color}})}),1)],1)],1),_c('b-col',{staticClass:"px-0 d-flex flex-wrap justify-content-start",attrs:{"cols":"12"}},[_c('PatientsCardProcedure',{attrs:{"title":'Review of System',"appointmentProcedure":_vm.appointmentDetail.item.office_visit.review_of_system}}),_c('PatientsCardProcedure',{attrs:{"title":'Diagnosis',"appointmentProcedure":_vm.appointmentDetail.item.office_visit.diagnosis}}),_c('PatientsCardProcedure',{attrs:{"title":'Laboratories',"appointmentProcedure":_vm.appointmentDetail.item.office_visit.laboratories}}),_c('PatientsCardProcedure',{attrs:{"title":'Interventions',"appointmentProcedure":_vm.appointmentDetail.item.office_visit.procedures.concat(
              _vm.appointmentDetail.item.office_visit.tests
            )}}),_c('PatientsCardProcedure',{attrs:{"title":'Treatments',"appointmentProcedure":_vm.appointmentDetail.item.office_visit.treatments}}),_c('PatientsCardProcedure',{attrs:{"title":'Diagnostic imaging',"appointmentProcedure":_vm.appointmentDetail.item.office_visit.diagnostic_imagings}})],1)],1)],1),_c('b-col',{attrs:{"md":"12","lg":"2","order":"6"}},[_c('b-button',{staticClass:"mb-75 button-print",attrs:{"variant":"outline-primary","block":""},on:{"click":_vm.printInvoice}},[_vm._v(" Print ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }