var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-0 m-0"},[_c('div',[_c('b-table',{staticClass:"position-relative text-center patient-appoinment",attrs:{"items":_vm.appointmentData,"fields":_vm.fields,"striped":"","bordered":"","responsive":"","hover":""},scopedSlots:_vm._u([{key:"cell(show_details)",fn:function(row){return [_c('b-form-checkbox',{staticClass:"text-center mx-auto",on:{"change":function($event){return _vm.changeDetails(row, row.item.id)}},model:{value:(row.detailsShowing),callback:function ($$v) {_vm.$set(row, "detailsShowing", $$v)},expression:"row.detailsShowing"}})]}},{key:"row-details",fn:function(row){return [_c('b-tabs',[_c('b-tab',{attrs:{"active":"","title":"Visit Summary"}},[_c('PatientsAppointmentView',{attrs:{"appointmentDetail":row}})],1),_c('b-tab',{staticStyle:{"width":"75vw !important"},attrs:{"title":"Invoice"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('RecipePatient',{attrs:{"isReview":true,"AppointmentId":row.item.id},on:{"copayIsApplied":_vm.changeCopay}})],1)],1)],1),(_vm.copay != null)?_c('b-tab',{staticStyle:{"width":"75vw !important"},attrs:{"title":"Co-pay"}},[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('RecipeInsurancePayment',{attrs:{"isPatientView":true,"dataPatient":Object.assign({}, {payment: _vm.copay},
                _vm.userData)}})],1)],1):_vm._e()],1)]}},{key:"cell(type_visits)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(data.item.type_visits.name),expression:"data.item.type_visits.name",modifiers:{"hover":true,"top":true}}],staticClass:"badge-glow",style:({ 'background-color': data.item.type_visits.color })},[_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(data.item.type_visits.prefix))])])],1)]}},{key:"cell(visit_status)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.getStatus(data.item.office_visit, 'name')),expression:"getStatus(data.item.office_visit, 'name')",modifiers:{"hover":true,"top":true}}],staticClass:"badge-glow",style:({ 'background-color': _vm.getStatus(data.item.office_visit, 'color') })},[_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(_vm.getStatus(data.item.office_visit, 'prefix')))])])],1)]}},{key:"cell(type_consultations)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.type_consultations ? data.item.type_consultations.name : "Not specified"))])]}},{key:"cell(facilities)",fn:function(data){return [_c('b-link',{attrs:{"to":{
          name: 'admin-facilities-view',
          params: { id: data.item.facilities.id },
        }}},[_c('span',[_vm._v(_vm._s(data.item.facilities.name))])])]}},{key:"cell(frontdesks)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":data.item.frontdesks.avatar}})]},proxy:true}],null,true)},[_c('b-link',{attrs:{"to":{
            name: 'web-settings-users',
            params: { id: data.item.frontdesks.id },
          }}},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap d-flex flex-column align-items-start"},[_c('span',[_vm._v(" "+_vm._s(data.item.frontdesks.name)+" ")]),_c('span',[_vm._v(" "+_vm._s(data.item.frontdesks.lastname)+" ")])])])],1)]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[(
            data.item.office_visit.visit_statuses_id != 6 &&
            _vm.$can('appointment_refund', 'module_patient.patient')
          )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Refund'),expression:"'Refund'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-warning"},on:{"click":function($event){return _vm.refundAppointment(data.item.id)}}},[_c('feather-icon',{attrs:{"icon":"RotateCcwIcon"}})],1):_vm._e(),(
            _vm.isOpenThisVisit(data.item.office_visit.visit_statuses_id) &&
            !data.item.is_insurance_appointment &&
            _vm.$can('appointment_transform_to_insurance', 'module_patient.patient')
          )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Transform to insurance'),expression:"'Transform to insurance'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-primary"},on:{"click":function($event){return _vm.ChangeBasics(data.item.id, 'tInsurance')}}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1):_vm._e(),(
            _vm.isOpenThisVisit(data.item.office_visit.visit_statuses_id) &&
            data.item.is_insurance_appointment &&
            _vm.$can('appointment_update_insurance', 'module_patient.patient')
          )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Update Insurance'),expression:"'Update Insurance'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-primary"},on:{"click":function($event){return _vm.ChangeBasics(data.item.id, 'uInsurance')}}},[_c('feather-icon',{attrs:{"icon":"ToolIcon"}})],1):_vm._e(),(
            _vm.isOpenThisVisit(data.item.office_visit.visit_statuses_id) &&
            data.item.is_insurance_appointment &&
            _vm.$can('appointment_transform_to_self_pay', 'module_patient.patient')
          )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Transform to self-pay'),expression:"'Transform to self-pay'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-primary"},on:{"click":function($event){return _vm.toSelfPay(data.item.id)}}},[_c('feather-icon',{attrs:{"icon":"ZapIcon"}})],1):_vm._e(),(
            data.item.office_visit.omas &&
            data.item.office_visit.visit_statuses_id != 6 &&
            _vm.$can('change_oma', 'module_patient.patient')
          )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Change OMA'),expression:"'Change OMA'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-primary"},on:{"click":function($event){return _vm.changeOMA(data.item)}}},[_c('feather-icon',{attrs:{"icon":"UserPlusIcon"}})],1):_vm._e(),(data.item.office_visit.visit_statuses_id != 6 && _vm.$can('change_facility', 'module_patient.patient'))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Change Facility'),expression:"'Change Facility'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-primary"},on:{"click":function($event){return _vm.changeFacility(data.item)}}},[_c('feather-icon',{attrs:{"icon":"HomeIcon"}})],1):_vm._e(),(
            data.item.office_visit.visit_statuses_id != 5 &&
            data.item.office_visit.visit_statuses_id != 6 &&
            _vm.$can('appointment_delete', 'module_patient.patient')
          )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Delete'),expression:"'Delete'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.deleteAppointment(data.item.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1):_vm._e(),(
            data.item.office_visit.visit_statuses_id === 5 &&
            data.item.office_visit.visit_statuses_id != 6 &&
            _vm.$can('appointment_reopen', 'module_patient.patient')
          )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Re-open visit'),expression:"'Re-open visit'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-dark"},on:{"click":function($event){return _vm.reopenAppointment(data.item.id)}}},[_c('feather-icon',{attrs:{"icon":"RewindIcon"}})],1):_vm._e(),(data.item.office_visit.visit_statuses_id != 6 &&
            _vm.$can('appointment_reopen', 'module_patient.patient'))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Change type of visit'),expression:"'Change type of visit'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-primary"},on:{"click":function($event){return _vm.changeTypeVisit(data.item)}}},[_c('feather-icon',{attrs:{"icon":"RotateCcwIcon"}})],1):_vm._e(),(data.item.office_visit.visit_statuses_id != 6 &&
            _vm.$can('appointment_reopen', 'module_patient.patient'))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Change visit type to online'),expression:"'Change visit type to online'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-primary"},on:{"click":function($event){return _vm.changeTypeVisitOnline(data.item.id)}}},[_c('feather-icon',{attrs:{"icon":"RotateCwIcon"}})],1):_vm._e()],1)]}}])})],1),(_vm.conditionChangeBasics)?_c('ChangeBasics',{attrs:{"appointmentById":_vm.appointmentById,"methodCB":_vm.methodCB},on:{"closeModalPay":function($event){_vm.conditionChangeBasics = false},"NotificationCheck":_vm.NotificationCheck}}):_vm._e(),(_vm.conditionFacilityAppointment)?_c('ChangeFacility',{attrs:{"appointmentById":_vm.appointmentById},on:{"closeModalFacility":function($event){_vm.conditionFacilityAppointment = false},"NotificationCheck":_vm.NotificationCheck}}):_vm._e(),(_vm.conditionRefund)?_c('RefundAppointment',{attrs:{"appointmentById":_vm.appointmentById},on:{"closeModalPay":function($event){_vm.conditionRefund = false},"NotificationCheck":_vm.NotificationCheck}}):_vm._e(),(_vm.conditionOmaAppointment)?_c('ChangeOma',{attrs:{"appointmentById":_vm.appointmentById},on:{"closeModalFacility":function($event){_vm.conditionOmaAppointment = false},"NotificationCheck":_vm.NotificationCheck}}):_vm._e(),(_vm.conditionchangeVisitAppointment)?_c('ChangeTypeOfVisit',{attrs:{"dataAppointment":_vm.appointmentById},on:{"closeModalFacility":function($event){_vm.conditionchangeVisitAppointment = false},"NotificationCheck":_vm.NotificationCheck}}):_vm._e(),_c('ToastNotification',{ref:"toast"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }