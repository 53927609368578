<template>
  <b-row>
    <b-col md="12" lg="10">
      <b-row md="4" lg="6">
        <b-col cols="12" class="d-flex justify-content-between">
          <b-card class="w-75" body-class="pt-1 p-0">
            <statistic-card-vertical
              icon="HomeIcon"
              :statistic="appointmentDetail.item.office_visit.room"
              :statistic-title="'Room'"
              color="primary"
            />
          </b-card>
          <b-card class="text-center ml-1 w-75">
            <b-avatar
              class="mb-1"
              size="30"
              :src="appointmentDetail.item.office_visit.nurses.avatar"
            >
            </b-avatar>
            <div class="text-center d-flex flex-column">
              <span class="font-weight-bold">NURSE</span>
              <b-link
                :to="{
                  name: 'web-settings-users',
                  params: { id: appointmentDetail.item.office_visit.nurses.id },
                }"
              >
                <span>
                  {{ appointmentDetail.item.office_visit.nurses.name }}
                  {{ appointmentDetail.item.office_visit.nurses.lastname }}
                </span>
              </b-link>
            </div>
          </b-card>
          <b-card class="text-center ml-1 w-75">
            <b-avatar
              class="mb-1"
              size="30"
              :src="appointmentDetail.item.office_visit.omas.avatar"
            >
            </b-avatar>
            <div class="text-center d-flex flex-column">
              <span class="font-weight-bold">OMA</span>
              <b-link
                :to="{
                  name: 'web-settings-users',
                  params: { id: appointmentDetail.item.office_visit.omas.id },
                }"
              >
                <span>
                  {{ appointmentDetail.item.office_visit.omas.name }}
                  {{ appointmentDetail.item.office_visit.omas.lastname }}
                </span>
              </b-link>
            </div>
          </b-card>
          <b-card class="w-75 ml-1" body-class="pt-1 p-0">
            <statistic-card-vertical
              icon="DollarSignIcon"
              :statistic="appointmentDetail.item.total"
              :statistic-title="'Total'"
              color="primary"
            />
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card
            title="Vital signs"
            class="card-transaction"
            body-class="pt-1 p-0"
          >
            <div class="d-flex justify-content-around">
              <div
                lg="4"
                md="3"
                xl="3"
                v-for="(item, i) in appointmentDetail.item.office_visit
                  .type_vital_sign"
                :key="i"
              >
                <statistic-card-vertical
                  icon="ActivityIcon"
                  :statistic="item.pivot.value"
                  :statistic-title="item.prefix"
                  color="primary"
                />
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card
            title="Visit Timeline"
            class="card-transaction mb-1"
            body-class="pt-1 p-0"
            title-tag="h6"
          >
            <AppTimelineHorizontal>
              <AppTimelineHorizontalItem
                v-for="(item, i) in appointmentDetail.item.office_visit
                  .visit_status_auditor"
                :key="i"
                :title="item.name"
                :sub-title="item.pivot.description"
                :time="item.pivot.created_at"
                :variant="item.color"
              />
            </AppTimelineHorizontal>
          </b-card>
        </b-col>
        <b-col cols="12" class="px-0 d-flex flex-wrap justify-content-start">
          <PatientsCardProcedure
            :title="'Review of System'"
            :appointmentProcedure="
              appointmentDetail.item.office_visit.review_of_system
            "
          />
          <PatientsCardProcedure
            :title="'Diagnosis'"
            :appointmentProcedure="
              appointmentDetail.item.office_visit.diagnosis
            "
          />
          <PatientsCardProcedure
            :title="'Laboratories'"
            :appointmentProcedure="
              appointmentDetail.item.office_visit.laboratories
            "
          />
          <PatientsCardProcedure
            :title="'Interventions'"
            :appointmentProcedure="
              appointmentDetail.item.office_visit.procedures.concat(
                appointmentDetail.item.office_visit.tests
              )
            "
          />
          <PatientsCardProcedure
            :title="'Treatments'"
            :appointmentProcedure="
              appointmentDetail.item.office_visit.treatments
            "
          />
          <PatientsCardProcedure
            :title="'Diagnostic imaging'"
            :appointmentProcedure="
              appointmentDetail.item.office_visit.diagnostic_imagings
            "
          />
        </b-col>
      </b-row>
    </b-col>
    <b-col md="12" lg="2" order="6">
      <b-button
        variant="outline-primary"
        class="mb-75 button-print"
        block
        @click="printInvoice"
      >
        Print
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAvatar,
  BCard,
  BLink,
  BCol,
  BRow,
  BBadge,
  BButton,
} from "bootstrap-vue";
import AppTimelineHorizontal from "@core/components/app-timeline/AppTimelineHorizontal.vue";
import AppTimelineHorizontalItem from "@core/components/app-timeline/AppTimelineHorizontalItem.vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import PatientsCardProcedure from "./PatientsCardProcedure.vue";

export default {
  components: {
    BAvatar,
    BCard,
    BCol,
    BRow,
    BLink,
    BBadge,
    BButton,
    StatisticCardVertical,
    AppTimelineHorizontal,
    AppTimelineHorizontalItem,
    PatientsCardProcedure,
  },
  props: {
    appointmentDetail: {
      type: Object,
      required: true,
    },
  },
  methods: {
    printInvoice() {
      window.print();
    },
  },
};
</script>

<style lang="scss">
@media print {
  .button-print{
    display: none;
  }
  .nav.nav-pills{
    display: none;
  }
  .button-back{
    display: none;
  }
  .th-class-adjusted div{
    color: #fff;
  }
}
</style>
