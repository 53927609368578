<template>
  <b-card class="p-0 m-0">
    <modalMembership v-if="conditionFacilityAppointment" :title="titleModal" :size="size" :fields="fieldsModal" :items="itemsModal"
      @closeModalFacility="conditionFacilityAppointment = false" />
    <div>
      <b-table :items="membershipHistory" :fields="fields" striped bordered responsive
        class="position-relative text-center patient-appoinment" hover>
        <template #cell(show_details)="row">
          <!-- activar el checkboz actual y desactivar los demas -->
          <b-form-checkbox v-model="row.detailsShowing" class="text-center mx-auto"
            @change="changeDetails(row, row.item.id)">
          </b-form-checkbox>
        </template>
        <template #row-details="row">
          <b-card-group v-if="row.item.tracking">
            <div class="w-100 pt-3" v-for="(title, index) in Object.keys(row.item.tracking)" :key="index"
              v-if="row.item.tracking[title].length">
              <b-button variant="primary" block v-b-toggle=title>{{ getName(title) }}</b-button>
              <b-collapse :id="title" accordion="my-accordion" role="tabpanel">
                <b-table :fields="['id', 'name', 'usage', 'Renewal']" :items="row.item.tracking[title]">
                  <template #cell(Renewal)="data">
                    <b-button v-if="$can('change_facility', 'module_patient.patient') && data.item && data.item.usages.is_renewal"
                      variant="flat-primary" class="btn-icon" v-b-tooltip.hover.top="'See usage'"
                      @click="data.item.usages.is_renewal ? changeFacility(data.item) : changeDetails(data, data.item.id)">
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                    <p v-else>Unlimited</p>
                  </template>
                  <template #row-details="row2">
                    <b-card>
                      <b-table :items="row2.item.usages.length ? row2.item.usages : [row2.item.usages]">
                      </b-table>
                    </b-card>
                  </template>
                  <template #cell(usage)="data">
                    {{ data.item.usages.usage }}
                  </template>
                </b-table>
              </b-collapse>
            </div>
          </b-card-group>
        </template>
        <template #cell(actions)="data">
          <b-button v-if="$can('change_facility', 'module_patient.patient') && data.item" variant="flat-primary"
            class="btn-icon" v-b-tooltip.hover.top="'See Appointments'" @click="changeFacility(data.item, true)">
            <feather-icon icon="BookOpenIcon" />
          </b-button>
        </template>
      </b-table>
    </div>
    <ToastNotification ref="toast" />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardGroup,
  BTable,
  BTabs,
  BTab,
  BFormCheckbox,
  BCol,
  BRow,
  BButton,
  BMedia,
  BAvatar,
  BBadge,
  BLink,
  BCollapse,
  VBToggle,
  VBTooltip,
} from "bootstrap-vue";
import NotificationMixin from "@/mixins/NotificationMixin";

import ToastNotification from "@/components/ToastNotification";
import ChangeFacility from "./actions/ChangeFacility.vue";
import ChangeOma from "./actions/ChangeOma.vue";
import ChangeTypeOfVisit from "./actions/ChangeTypeOfVisit.vue";
import RefundAppointment from "./actions/RefundAppointment";
import RecipePatient from "@/views/appointment/appointment-recipes/RecipePatient.vue";
import RecipeInsurancePayment from "@/views/appointment/appointment-recipes/RecipeInsurancePayment.vue";
import PatientsAppointmentView from "@/views/patients/patients-view/PatientsAppointmentView.vue";
import modalMembership from "./actions/modalMembership.vue";

export default {
  components: {
    BCard,
    modalMembership,
    BCollapse,
    BCardGroup,
    BCardHeader,
    BCardTitle,
    BTable,
    BTabs,
    BTab,
    BFormCheckbox,
    BCol,
    BRow,
    BButton,
    BMedia,
    BAvatar,
    BBadge,
    BLink,
    ToastNotification,
    ChangeFacility,
    ChangeOma,
    ChangeTypeOfVisit,
    RefundAppointment,
    RecipePatient,
    RecipeInsurancePayment,
    PatientsAppointmentView,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  props: {
    membershipHistory: {
      type: Array,
      default: () => { },
      required: true,
    },
  },
  mixins: [NotificationMixin],
  data() {
    return {
      fields: [
        { key: "show_details", class: "th-class-adjusted" },
        { key: "id", sortable: true, class: "th-class-adjusted" },
        {
          key: "name",
          label: "Name",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "membership_role",
          label: "Membership role",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "price",
          label: "Price",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "quantity_uses",
          label: "Quantity uses",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "beneficiaries",
          label: "Beneficiaries",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "activation_date",
          label: "Activation Date",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "due_date",
          label: "Due Date",
          sortable: true,
          class: "th-class-adjusted",
        },
        { key: "actions", label: "Appointments", class: "th-class-adjusted" },
      ],
      conditionFacilityAppointment: false,
      titleModal: "",
      itemsModal: [],
      fieldsModal: [],
      size: "xl"
    };
  },
  methods: {
    getName(title) {
      return title.split("_").join(" ")
    },
    changeCopay(item) {
      this.copay = item;
    },
    changeDetails(item) {
      item.toggleDetails();
    },
    changeFacility(item, appointment) {
      this.conditionFacilityAppointment = true;
      this.titleModal = item.name
      let newItems
      if (appointment) {
        newItems = item.appointments
        this.fieldsModal = ['id', 'visit_date', 'total', 'facility', 'frontdesk', 'oma', 'nurse']
        this.size = "xl"
      } else {
        this.fieldsModal = []
        this.size = "lg"
        newItems = item.usages.periods
      }
      this.itemsModal = newItems
    },
  },
};
</script>

<style lang="scss">
.b-table-details {
  background-color: #fafafc;
}
</style>
