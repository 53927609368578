<template>
  <b-modal id="modal-lg" v-model="conditionFacility" hide-footer hide-header centered :size="size" @hide="onHide">
    <h3>{{ title }}</h3>
    <br />
    <b-table :fields="fields" :items="items">
      <template #cell(oma)="data">
        {{ data.item.oma_name }}
      </template>
      <template #cell(nurse)="data">
        {{ data.item.nurse_name }}
      </template>
      <template #cell(frontdesk)="data">
        {{ data.item.frontdesk_name }}
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import { BButton, BModal, BFormGroup, BTable } from "bootstrap-vue";
export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BTable,
  },
  data() {
    return {
      conditionFacility: true,
      optionsFacility: [],
      loading: false,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onHide() {
      this.$emit("closeModalFacility", false);
    },
  },
};
</script>

<style lang="scss"></style>