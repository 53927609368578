<template>
  <b-card class="p-0 m-0">
    <div v-if="userData">
      <b-table
        :items="userData"
        :fields="fields"
        striped
        bordered
        responsive
        class="position-relative text-center patient-appoinment"
        hover
      >
        <template #cell(users)="data">
          <div class="text-nowrap">
            <b-link
              :to="{
                name: 'web-settings-users',
                params: { id: data.item.frontdesk_id },
              }"
            >
              <span>
                {{ data.item.frontdesk_name }}
              </span>
            </b-link>
          </div>
        </template>
        <template #cell(description)="data">
          <div
            class="text-container"
            v-b-tooltip.hover.top="'Click here to see full description'"
            v-b-modal.modal-1
            @click="
              description = data.item.description;
              movements = data.item.movements;
            "
          >
            {{ data.item.description }}
          </div>
        </template>
      </b-table>
    </div>
    <b-modal
      id="modal-1"
      title="More Details"
      :size="activeTab == 0 ? 'sm' : 'lg'"
    >
      <b-tabs pills v-model="activeTab">
        <b-tab active>
          <template #title>
            <span>Description</span>
          </template>
          <b-card border-variant="success" align="center">
            {{ description }}
          </b-card>
        </b-tab>
        <b-tab>
          <template #title>
            <span>Movements</span>
          </template>
          <b-table
            striped
            bordered
            responsive
            hover
            :items="movements"
            :fields="movementFields"
          />
        </b-tab>
      </b-tabs>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BModal,
  BCardTitle,
  BTable,
  BTabs,
  BTab,
  BFormCheckbox,
  BCol,
  BRow,
  BButton,
  BMedia,
  BAvatar,
  BBadge,
  BLink,
  VBTooltip,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BModal,
    BCardHeader,
    BCardTitle,
    BTable,
    BTabs,
    BTab,
    BFormCheckbox,
    BCol,
    BRow,
    BButton,
    BMedia,
    BAvatar,
    BBadge,
    BLink,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    userData: {
      type: Array,
      default: [],
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      description: "",
      movements: [],
      movementFields: [
        "id",
        { key: "movement_type", label: "Event" },
        "transaction_id",
        "value",
      ],
      fields: [
        { key: "id", sortable: true, class: "th-class-adjusted" },
        {
          key: "users",
          label: "FrontDesk",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "total",
          label: "total",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "description",
          label: "description",
          sortable: true,
          class: "th-class-adjusted",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.th-class-adjusted {
  padding: 0.9rem 0.1rem !important;
}

.b-table-details {
  background-color: #fafafc;
}
.text-container {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
</style>
