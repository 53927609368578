<template>
  <li class="li complete py-1">
    <div class="timestamp">
      <small class="date">{{
        new Date(time).toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        })
      }}</small>
    </div>
    <b-avatar
      size="20"
      icon="null"
      :style="`background-color: ${variant};`"
    />
    <div class="status-line" :style="'border-top: solid 2px ' + variant"></div>
    <h6
      class="text-left mt-1"
    >
      {{ title }}
    </h6>
  </li>
</template>

<script>
import { BAvatar } from "bootstrap-vue";
export default {
  components: {
    BAvatar,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    time: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'HomeIcon',
    },
    fillBorder: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "primary",
    },
  },
};
</script>

<style lang="scss" scoped>
.li {
  transition: all 200ms ease-in;
}
.timestamp {
  padding: 0px 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 100;
}
.status-line {
  width: 14rem;
  position: relative;
  bottom: 10px;
}
.li.complete {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-device-width: 320px) and (max-device-width: 700px) {
  .timeline {
    list-style-type: none;
    display: block;
  }
  .li {
    transition: all 200ms ease-in;
    display: flex;
    width: inherit;
  }
  .timestamp {
    width: 100px;
  }
}
</style>
